/* eslint-disable no-mixed-spaces-and-tabs */
import m, * as bacta from "bacta"
import * as L from "leaflet"
import legacyCSS from "./main.module.css"
import { HarthSelect } from "./components/harth/select"
import { HarthCheckbox } from "./components/harth/checkbox"
import { HarthInput } from "./components/harth/input"
import { HarthTextArea } from "./components/harth/textarea"
import { HarthButton } from "./components/harth/button"
import { HarthIcon } from "./components/harth/icon"
import { HarthSpinner } from "./components/harth/spinner"
import HarthUppy from "./components/harth/harth-uppy"

import {
	initialize,
	price,
	here,
	works,
	consent,
	optout,
} from "./utils/signed-query"

import { HarthCard } from "./components/harth/card"
import { HarthTabGroup } from "./components/harth/tab"
import { HarthDetails } from "./components/harth/details"
import { FormalAddress, State, useStore, restrictedarea } from "./store"
import { money, pretty } from "./utils/regexes"
import { itemType } from "./types"
import * as R from "ramda"

import {
	HarthRadioGroup,
	HarthRadioButton,
} from "./components/harth/radio-group"
import { privacyPolicy } from "./privacy-policy"
import { termsOfService } from "./terms-of-service"
import { HarthDivider } from "./components/harth/divider"
import { HarthStripe } from "./components/harth/stripe"
import { HarthMenu } from "./components/harth/menu"
import { Button, ButtonAttrs } from "./components/sl/button"
import { Link } from "./components/harth/link"
import { Dialog, DialogAttrs } from "./components/sl/dialog"
import { HarthMap, Map } from "./components/harth/map"

// const dologo = "/assets/evgen_logo_black_on_transparent.png"
// const dologo = "/assets/evgen_logo_purple_on_transparent_no_plug.png"
const dologo = "/assets/evgen_logo_purple_on_transparent.png"
const plug = "/assets/plug_purple_on_transparent.png"
const websiteHome = "/assets/website-landing169CS.png"
const introstep1 = "/assets/3 Step Process1.gif"
const introstep2 = "/assets/3 Step Process2.gif"
const introstep3 = "/assets/3 Step Process3.gif"
const varrow = "/assets/purplearrow.png"

const css = bacta.css
const pp = m.trust(privacyPolicy)
const ts = m.trust(termsOfService)

function debounce(f: (...args: any[]) => void | Promise<void>, ms = 250) {
	let id: string | number | NodeJS.Timeout | undefined
	return function (...args: any[]) {
		clearTimeout(id)
		id = setTimeout(async function () {
			await f(...args)
		}, ms)
	}
}

const centeredHeader = css`
	& {
		text-align: center;
		align-items: center;
		font-size: 1.25em;
		font-weight: 500;
	}
`

document.body.classList.add(legacyCSS.body)

function autocomplete(
	options: Array<string> = [],
	query: bacta.Store<string> | bacta.Store<string[]>,
	isnew: bacta.Store<boolean>,
	help: string,
	boxtype: string,
	width: string,
	required: boolean,
	disabled: any,
	format: string
) {
	return m(".app.", { style: { width, "--textalign": format } }, [
		!isnew.get() && options.length
			? m(HarthSelect, {
					query: query,
					options: options,
					sl: {
						multiple: false,
						placement: "bottom",
						size: "medium",
						hoist: true,
						helpText: help,
						clearable: false,
						class: "borderBottom",
						disabled: disabled(),
					},
			  })
			: boxtype == "input"
			? m(HarthInput, {
					query: query as bacta.Store<string>,
					type: "text",
					sl: {
						size: "medium",
						helpText: help,
						class: "borderBottom",
						clearable: false,
						required: required,
						disabled: disabled(),
					},
			  })
			: m(HarthTextArea, {
					query: query as bacta.Store<string>,
					type: "text",
					sl: {
						placeholder: "",
						size: "small",
						helpText: help,
						disabled: disabled(),
					},
			  }),

		options.length
			? m(
					HarthButton,
					{
						query: isnew,
						sl: {
							size: "medium",
							variant: "default",
							outline: false,
							value: isnew.get() ? false : true,
							class: "whitebackground",
							disabled: disabled(),
						},
					},
					[
						m(HarthIcon, {
							sl: {
								name: isnew.get() ? "x-lg" : "pencil-square",
								slot: "suffix",
								style: { "font-size": "18px" },
							},
						}),
					]
			  )
			: null,
	])
}

async function patchConsent(store: any, query: any, consentobj: any) {
	const email = store.prop("order_email").get()
	const user_id = store.prop("user").get().user_id || ""
	const result = await consent({ ...consentobj, email, user_id })
	// if (consentobj.get) {
	// 	query.update(() => (result ? true : false))
	// }
	return result
}

function questionsPaymentInputs(v: bacta.Vnode) {
	const { store, c, l } = useStore()

	const contact_name = store.prop("order_contact_name").get()
	const phone = store.prop("order_phone").get()
	const email = store.prop("order_email").get()
	const order = store.prop("checkoutindex").get()[0]
	const price = R.sum(
		store
			.prop("checkoutindex")
			.get()
			.map((c) => c.price - c.paid) ?? 0
	)
	const deposit = Number(Number(price * 0.1).toFixed(2))
	const amount = !order.paid ? deposit : price
	const address = store.prop("FormalAddress").get()

	v.useEffect({ name: "set-terms-update" }, function* () {
		const { terms } = useStore()
		const [t] = yield [terms]
		if (t == false || t == true) {
			debounceConsent(store, t, {
				set: t,
				del: t == false,
				id: "0c5cbf38-6896-4361-bb43-70b8a894bda7",
			})
		}
	})

	v.useEffect({ name: "set-privacy-update" }, function* () {
		const { privacy } = useStore()
		const [p] = yield [privacy]
		if (p == false || p == true) {
			yield debounceConsent(store, p, {
				set: p,
				del: p == false,
				id: "0a0c1453-8a7e-41ac-99b9-b9746913c077",
			})
		}
	})

	return m(
		".payment-inputs",
		bacta.Stream.merge([
			store.prop("order_status").get as any,
			store.prop("terms").get as any,
			store.prop("privacy").get as any,
			store.prop("paydeposit").get as any,
			store.prop("checkoutindex").get as any,
			l.get as any,
		]).map(([o, t, p, pd, co, l]) => [
			m(
				"header",
				centeredHeader,
				"Booking",
				m(HarthDivider, { sl: { vertical: false }, centered: true })
			),

			m(
				"." + legacyCSS.spreadRowsBigGap,

				m(
					".top-section",
					css`
						& {
							display: grid;
							padding: 0em 0em;
							gap: 1em;
						}

						@media (min-aspect-ratio: 8/10) {
							& {
								padding: 0em 6em;
							}
						}
					`,
					m(
						"." + legacyCSS.spreadColumnsCenter,
						css`
							align-self: start;
							margin-top: 0em;
						`,
						m(HarthIcon, {
							sl: {
								name: "info-circle",
								style: {
									color: "#a649d9",
									"font-size": "36px",
									"align-self": "start",
									height: "0.7em",
								},
							},
						}),
						m(
							"." + legacyCSS.leftText,
							`Additional charges may apply if variations are required or your property circumstances differ from the information provided for the quote`
						)
					),
					m(
						"." + legacyCSS.spreadRowsNoGap,
						css`
							& {
								display: grid;
								justify-content: center;
							}
						`,
						o == "Payment Completed" || store.prop("outstanding").get() == 0
							? m("." + legacyCSS.leftTitles, "Payment Completed")
							: m("." + legacyCSS.leftTitles, "Inclusions"),

						m(
							".items",
							css`
								& {
									display: grid;
								}
							`,
							c.get().map((i) =>
								m(
									"ul." + legacyCSS.leftText,
									css`
										& {
											padding-left: 0px;
										}
										& li {
											list-style: disc;
											text-align: left;
											margin-left: 2em;
										}
									`,
									i.inclusions.map((inc) => m("li." + legacyCSS.helper, inc))
								)
							)
						),

						m(
							"." + legacyCSS.leftText,
							m("." + legacyCSS.leftTitles, "Price "),
							money(price) + " Incl. gst"
						)
					),

					// m(
					// 	"." + legacyCSS.leftText,
					// 	m("." + legacyCSS.leftTitles, "Ready to proceed?")
					// ),
					m(
						"." + legacyCSS.leftText,
						order.paid
							? "Please select a payment option from the below to complete your order. Feel free to contact us at sales@evgen.com.au for any other questions."
							: "Select a date and time below to book your free site inspection. Once inspected we'll finalise your quote and if you're happy to proceed, we will arrange installation."
						// "Select a payment option from the below. Once payment is made we'll schedule the install and be in contact shortly to confirm a visit. Feel free to contact us at sales@evgen.com.au for any other questions."
					)

					// m(
					// 	"." + legacyCSS.leftText,
					// 	m("." + legacyCSS.leftTitles, "Not ready to to install yet?")
					// ),
					// m(
					// 	"." + legacyCSS.leftText,
					// 	"We've emailed you a link to this quote, so you can come back to it at anytime. Feel free to close this window or contact us at sales@evgen.com.au for any other questions."
					// )
				),

				pd
					? null
					: m(HarthInput, {
							query: store.prop("dateframe"),
							type: "date",
							sl: {
								id: "dateframe",
								size: "medium",
								helpText: "Preferred Inspection Date",
								clearable: false,
								required: true,
								min: new Date().toISOString().slice(0, 10),
								max: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365)
									.toISOString()
									.slice(0, 10),
								disabled:
									store.prop("order_status").get() == "Payment Completed",
							},
					  }),

				pd
					? null
					: () =>
							store.prop("dateframe").get.map((d) => {
								return d &&
									store
										.prop("checkoutindex")
										.get()[0]
										.unavailable.find((a) => a == new Date(d).toISOString())
									? m(
											"",
											m("", `Unavailble Dates: `),
											m(
												"",
												css`
													& {
														display: flex;
														flex-direction: row;
														flex-wrap: wrap;
														gap: 0.5em;
													}
												`,
												store
													.prop("checkoutindex")
													.get()[0]
													.unavailable.map((d) =>
														m(
															"" + legacyCSS.leftText,
															css`
																& {
																	color: red;
																	font-weight: 500;
																	min-width: 5.5em;
																}
															`,
															new Date(d).toLocaleDateString()
														)
													)
											)
									  )
									: null
							}),

				pd
					? null
					: m(HarthSelect, {
							query: store.prop("timeframe"),
							options: ["AM", "PM"],
							sl: {
								id: "timeframe",
								multiple: false,
								placement: "bottom",
								size: "medium",
								helpText: "Inspection Time",
								hoist: true,
								clearable: false,
								disabled:
									store.prop("order_status").get() == "Payment Completed",
							},
					  }),

				pd
					? null
					: m(
							".app." + legacyCSS.spreadRowsNoGap,
							m(".app." + legacyCSS.helper, "AM - 8am and 12am"),
							m(".app." + legacyCSS.helper, "PM - 12pm and 5:30pm")
					  ),
				pd
					? null
					: m(
							HarthInput,
							{
								query: store.prop("order_instructions"),
								type: "text",
								sl: {
									size: "medium",
									helpText: "Notes for Inspector",
									clearable: true,
									required: false,
									disabled:
										store.prop("order_status").get() == "Payment Completed",
								},
							},
							m("sl-icon", { name: "card-list", slot: "prefix" })
					  ),

				pd || l
					? null
					: (new Date(
							store
								.prop("checkoutindex")
								.get()[0].description.inspectiondefaultime
					  )
							.toISOString()
							?.slice(0, 10) as unknown as Date | null) ==
					  store.prop("dateframe").get()
					? m(
							"",
							store.prop("checkoutindex").get()[0].description
								.inspectiondefaultime
								? m(
										"." + legacyCSS.centeredHeader,
										css`
											& {
												color: #a649d9;
												padding-bottom: 0.5em;
											}
										`,
										"Inspection Booked!"
								  )
								: null,

							store.prop("checkoutindex").get()[0].description
								.inspectiondefaultime
								? m(
										"." + legacyCSS.centeredtext,
										"You may re-schedule by selecting alternate dates above"
								  )
								: null
					  )
					: m(
							HarthButton,
							{
								id: "schedule-inspection",
								query: store.prop("scheduleinspection"),
								sl: {
									size: "large",
									variant: "default",
									outline: true,
									value: true,
									class: "",
									disabled:
										(new Date(
											store
												.prop("checkoutindex")
												.get()[0].description.inspectiondefaultime
										)
											.toISOString()
											?.slice(0, 10) as unknown as Date | null) ==
											store.prop("dateframe").get() ||
										(store
											.prop("checkoutindex")
											.get()[0]
											.unavailable.find(
												(a) =>
													a ==
													new Date(
														store.prop("dateframe").get() as Date
													).toISOString()
											)
											? true
											: false),
								},
							},
							!store.prop("checkoutindex").get()[0].description
								.inspectiondefaultime
								? "Schedule Inspection"
								: "Re-Schedule Inspection"
					  ),

				m(HarthDivider, { sl: { vertical: false }, centered: true }),

				m(
					"." + legacyCSS.leftText,
					m("." + legacyCSS.leftTitles, "Site inspected?")
				),
				m(
					"." + legacyCSS.leftText,
					"Use this link to schedule your installation and pay the deposit, ",
					m(
						HarthButton,
						{
							query: store.prop("paydeposit"),
							sl: {
								size: "large",
								variant: "text",
								outline: false,
								value: true,
								class: "inlineButton",
							},
						},
						" finalise quote."
					),
					" Balance is due on day of installation"
				),

				!pd || l
					? null
					: m(
							"." + legacyCSS.spreadRowsBigGap,
							{
								id: "paydeposit",
								style: { "min-height": "26em", height: "26em" },
							},

							m(HarthInput, {
								query: store.prop("dateframe"),
								type: "date",
								sl: {
									id: "dateframe",
									size: "medium",
									helpText: "Preferred Installation Date",
									clearable: false,
									required: true,
									min: new Date().toISOString().slice(0, 10),
									max: new Date(
										new Date().getTime() + 1000 * 60 * 60 * 24 * 365
									)
										.toISOString()
										.slice(0, 10),
									disabled:
										store.prop("order_status").get() == "Payment Completed",
								},
							}),

							() =>
								store.prop("dateframe").get.map((d) => {
									return d &&
										store
											.prop("checkoutindex")
											.get()[0]
											.unavailable.find((a) => a == new Date(d).toISOString())
										? m(
												"",
												m("", `Unavailble Dates: `),
												m(
													"",
													css`
														& {
															display: flex;
															flex-direction: row;
															flex-wrap: wrap;
															gap: 0.5em;
														}
													`,
													store
														.prop("checkoutindex")
														.get()[0]
														.unavailable.map((d) =>
															m(
																"" + legacyCSS.leftText,
																css`
																	& {
																		color: red;
																		font-weight: 500;
																		min-width: 5.5em;
																	}
																`,
																new Date(d).toLocaleDateString()
															)
														)
												)
										  )
										: null
								}),

							m(HarthSelect, {
								query: store.prop("timeframe"),
								options: ["AM", "PM"],
								sl: {
									id: "timeframe",
									multiple: false,
									placement: "bottom",
									size: "medium",
									helpText: "Installation Time",
									hoist: true,
									clearable: false,
									disabled:
										store.prop("order_status").get() == "Payment Completed",
								},
							}),

							m(
								".app." + legacyCSS.spreadRowsNoGap,
								m(".app." + legacyCSS.helper, "AM - 8am and 12am"),
								m(".app." + legacyCSS.helper, "PM - 12pm and 5:30pm")
							),

							(new Date(
								store.prop("checkoutindex").get()[0].description.defaultime
							)
								.toISOString()
								?.slice(0, 10) as unknown as Date | null) ==
								store.prop("dateframe").get()
								? m(
										"",
										store.prop("checkoutindex").get()[0].description
											.inspectiondefaultime
											? m(
													"." + legacyCSS.centeredHeader,
													css`
														& {
															color: #a649d9;
															padding-bottom: 0.5em;
														}
													`,
													"Install Booked!"
											  )
											: null,

										store.prop("checkoutindex").get()[0].description
											.inspectiondefaultime
											? m(
													"." + legacyCSS.centeredtext,
													"You may re-schedule by selecting alternate dates above"
											  )
											: null
								  )
								: m(
										HarthButton,
										{
											id: "schedule-install",
											query: store.prop("scheduleinstall"),
											sl: {
												size: "large",
												variant: "default",
												outline: true,
												value: true,
												class: "",
												disabled:
													(new Date(
														store
															.prop("checkoutindex")
															.get()[0].description.defaultime
													)
														.toISOString()
														?.slice(0, 10) as unknown as Date | null) ==
														store.prop("dateframe").get() ||
													(store
														.prop("checkoutindex")
														.get()[0]
														.unavailable.find(
															(a) =>
																a ==
																new Date(
																	store.prop("dateframe").get() as Date
																).toISOString()
														)
														? true
														: false),
											},
										},
										!store.prop("checkoutindex").get()[0].description.defaultime
											? "Schedule Install"
											: "Re-Schedule Install"
								  ),

							o != "Payment Completed" || store.prop("outstanding").get() > 0
								? m(HarthStripe, {
										status: store.prop("order_status").get,
										order: store.prop("order").get,
										error: store.prop("PaymentError").get,
										currency: "aud",
										address: store.prop("FormalAddress").get,
										amount: Number((amount * 10 * 10).toFixed(2)),
										writeToLoad,
										options: {
											defaultValues: {
												billingDetails: {
													name: contact_name,
													email: email,
													phone: phone,
													address: {
														line2: address?.address?.district,
														line1: [
															address?.address?.houseNumber,
															address?.address?.street,
														].join(" "),
														city: address?.address?.city,
														state: address?.address?.state,
														country: address?.address?.countryName,
														postal_code: address?.address?.postalCode,
													},
												},
											},
										},
										appearance: {
											theme: "stripe",
										},
								  })
								: null,
							o != "Payment Completed" || store.prop("outstanding").get() > 0
								? m(
										"." + legacyCSS.spreadColumnsCenter,
										{ id: "terms" },
										m(HarthCheckbox, {
											query: store.prop("terms"),
										}),
										m(
											"." + legacyCSS.leftText,
											m(
												"",
												"I agree to the ",
												m(
													"a",
													{
														href: "/terms",
														target: "_blank",
														id: "link-to-terms",
														disabled:
															o == "Payment Completed" &&
															!store.prop("outstanding").get(),
													},
													"Terms and Conditions"
												)
											)
										)
								  )
								: null,
							o != "Payment Completed" || store.prop("outstanding").get() > 0
								? m(
										"." + legacyCSS.spreadColumnsCenter,
										{ id: "privacy" },
										m(HarthCheckbox, {
											query: store.prop("privacy"),
										}),
										m(
											"." + legacyCSS.leftText,
											m(
												"",
												"I agree to the ",
												m(
													"a",
													{
														href: "/privacy",
														target: "_blank",
														id: "link-to-privacy",
														disabled:
															o == "Payment Completed" &&
															!store.prop("outstanding").get(),
													},
													"Privacy Policy"
												)
											)
										)
								  )
								: null,

							o != "Payment Completed" || store.prop("outstanding").get() > 0
								? // 	&& store.prop("privacy").get() &&
								  // 	store.prop("terms").get()
								  m(
										HarthButton,
										{
											id: "submit-pay",
											query: store.prop("paySubmit"),
											sl: {
												size: "large",
												variant: "default",
												outline: true,
												value: "Payment Attempted",
												class: "yellowbackground",
												disabled: !p || !t,
											},
										},
										"Pay Now " + money(amount)
								  )
								: null,

							() =>
								store
									.prop("PaymentError")
									.get.map((o) =>
										o
											? m(
													"." + legacyCSS.centeredTitles,
													{ style: { color: "red" } },
													store.prop("PaymentError").get()
											  )
											: null
									)
					  )
			),
		])
	)
}

function questionsInstallInformation(v: bacta.Vnode) {
	const { store, l, u, c } = useStore()

	v.useEffect({ name: "gw-update" }, function* () {
		const [wd, sg, cp] = yield [
			store.prop("workingdistance"),
			store.prop("separatedGarage"),
			store.prop("concretePavers"),
		]

		if (wd == 20 && sg == "YES" && cp == "YES") {
			store.prop("groundworkingdistance").update(() => 20)
		}
	})

	return m(".install-info", [
		m(
			"header",
			{ style: { color: "#a649d9" } },
			centeredHeader,
			"Install Information",
			m(HarthDivider, { sl: { vertical: false }, centered: true })
		),
		m(
			".",
			css`
				& {
					display: grid;
					grid-template-columns: minmax(0em, 40em);
					justify-content: center;
					gap: calc(var(--input-gap) * 2);
				}

				& > * {
					display: grid;
					gap: calc(var(--input-gap) * 1);
				}

				& > * > *:nth-child(odd) {
					text-align: left;
				}
				& > * > *:nth-child(even) {
					text-align: left;
				}
			`,

			m(
				"",
				m("p." + legacyCSS.leftText, "Are you the property owner?"),
				m(
					"",
					m(
						HarthRadioGroup,
						{
							query: store.prop("propertyowner"),
						},
						m(
							HarthRadioButton,
							{
								value: "YES",
								size: "small",
								class: "purple-fill",
								id: "propertyowner-yes",
								disabled:
									l.get() ||
									u.get() ||
									store.prop("order_status").get() == "Payment Completed",
							},
							"Yes"
						),
						m(
							HarthRadioButton,
							{
								value: "NO",
								size: "small",
								class: "purple-fill",
								id: "propertyowner-no",
								disabled:
									l.get() ||
									u.get() ||
									store.prop("order_status").get() == "Payment Completed",
							},
							"No"
						)
					)
				)
			),

			store.prop("propertyowner").get() == "YES"
				? m(
						"",
						m(
							"p." + legacyCSS.leftText,
							"Would you like us to Supply an EV Charger?"
						),
						m(
							"",
							m(
								HarthRadioGroup,
								{
									query: store.prop("charger") as bacta.Store<number>,
								},
								m(
									HarthRadioButton,
									{
										value: 0,
										size: "small",
										class: "purple-fill",
										id: "charger-no",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"No"
								),
								m(
									HarthRadioButton,
									{
										value: 1,
										size: "small",
										class: "purple-fill",
										id: "charger-yes",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"Yes"
								)
							)
						),

						(store.prop("charger").get() as number) > 0
							? m(
									".app." + legacyCSS.helper,
									"We supply a Tesla charger as our recommended standard"
							  )
							: null
				  )
				: null,

			// store.prop("propertyowner").get() == "YES"
			// 	? m(
			// 			"",
			// 			m(
			// 				"p." + legacyCSS.leftText,
			// 				"Is the electrical connection single phase or 3 phase?"
			// 			),
			// 			m(
			// 				"",
			// 				m(
			// 					HarthRadioGroup,
			// 					{
			// 						query: store.prop("phase"),
			// 					},
			// 					m(
			// 						HarthRadioButton,
			// 						{
			// 							value: "",
			// 							size: "small",
			// 							class: "purple-fill",
			// 							disabled:
			// 								l.get() ||
			// 								u.get() ||
			// 								store.prop("order_status").get() == "Payment Completed",
			// 						},
			// 						"Not Sure"
			// 					),
			// 					m(
			// 						HarthRadioButton,
			// 						{
			// 							value: "1",
			// 							size: "small",
			// 							class: "purple-fill",
			// 							disabled:
			// 								l.get() ||
			// 								u.get() ||
			// 								store.prop("order_status").get() == "Payment Completed",
			// 						},
			// 						"1"
			// 					),
			// 					m(
			// 						HarthRadioButton,
			// 						{
			// 							value: "3",
			// 							size: "small",
			// 							class: "purple-fill",
			// 							disabled:
			// 								l.get() ||
			// 								u.get() ||
			// 								store.prop("order_status").get() == "Payment Completed",
			// 						},
			// 						"3"
			// 					)
			// 				)
			// 			)
			// 	  )
			// 	: null,

			store.prop("propertyowner").get() == "YES" &&
				store.prop("charger").get() != null
				? m(
						"",
						m(
							"p." + legacyCSS.leftText,
							"Where would you like the Charger installed?"
						),
						m(
							"",
							m(
								HarthRadioGroup,
								{
									query: store.prop("evlocation"),
								},
								m(
									HarthRadioButton,
									{
										value: "Garage",
										size: "small",
										class: "purple-fill",
										id: "evlocation-garage",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"Garage"
								),
								m(
									HarthRadioButton,
									{
										value: "House",
										size: "small",
										class: "purple-fill",
										id: "evlocation-house",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"House"
								),
								m(
									HarthRadioButton,
									{
										value: "Other Place",
										size: "small",
										class: "purple-fill",
										id: "evlocation-other",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"Other Place"
								)
							)
						)
				  )
				: null,

			store.prop("evlocation").get() &&
				store.prop("propertyowner").get() == "YES"
				? m(
						"",

						m(
							"p." + legacyCSS.leftText,
							`What is the distance from the charging location to the switchboard ?`
							// `What is the approximate distance from the ${store
							// 	.prop("evlocation")
							// 	.get()} to the Main Switch Board?`
						),
						m(
							"",
							m(
								HarthRadioGroup,
								{
									query: store.prop("workingdistance"),
									sl: { id: "workingdistance" },
								},
								// m(
								// 	HarthRadioButton,
								// 	{
								// 		value: 10,
								// 		size: "small",
								// 		class: "purple-fill",
								// 		disabled:
								// 			l.get() ||
								// 			u.get() ||
								// 			store.prop("order_status").get() == "Payment Completed",
								// 	},
								// 	"< 10m"
								// ),
								m(
									HarthRadioButton,
									{
										value: 20,
										size: "small",
										class: "purple-fill",
										id: "workingdistance-20",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"< 20m"
								),
								m(
									HarthRadioButton,
									{
										value: 40,
										size: "small",
										class: "purple-fill",
										id: "workingdistance-40",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"< 40m"
								),
								m(
									HarthRadioButton,
									{
										value: 60,
										size: "small",
										class: "purple-fill",
										id: "workingdistance-60",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"< 60m"
								)
							)
						)
				  )
				: null,

			store.prop("workingdistance").get() &&
				store.prop("propertyowner").get() == "YES"
				? m(
						"",

						m("p." + legacyCSS.leftText, `How many storeys is your property?`),
						m(
							"",
							m(
								HarthRadioGroup,
								{
									query: store.prop("storey"),
									sl: { id: "storey" },
								},
								m(
									HarthRadioButton,
									{
										value: 1,
										size: "small",
										class: "purple-fill",
										id: "storey-single",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"Single"
								),
								m(
									HarthRadioButton,
									{
										value: 2,
										size: "small",
										class: "purple-fill",
										id: "storey-double",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"Double"
								),
								m(
									HarthRadioButton,
									{
										value: 3,
										size: "small",
										class: "purple-fill",
										id: "storey-triple",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"Triple"
								)
							)
						)
				  )
				: null,

			store.prop("storey").get() && store.prop("propertyowner").get() == "YES"
				? m(
						"",

						m(
							"p." + legacyCSS.leftText,
							`Is the ${store
								.prop("evlocation")
								.get()} detached from the Main Switch Board?`
						),
						m(
							"",
							m(
								HarthRadioGroup,
								{
									query: store.prop("separatedGarage"),
									sl: { id: "separatedGarage" },
								},
								m(
									HarthRadioButton,
									{
										value: "YES",
										size: "small",
										class: "purple-fill",
										id: "separatedGarage-yes",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"YES"
								),
								m(
									HarthRadioButton,
									{
										value: "NO",
										size: "small",
										class: "purple-fill",
										id: "separatedGarage-no",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"NO"
								)
							)
						)
				  )
				: null,

			store.prop("separatedGarage").get() == "YES" &&
				store.prop("propertyowner").get() == "YES"
				? m(
						"",

						m(
							"p." + legacyCSS.leftText,
							`Are there Concrete or Pavers between the Main Switch Board and ${store
								.prop("evlocation")
								.get()}?`
						),
						m(
							"",
							m(
								HarthRadioGroup,
								{
									query: store.prop("concretePavers"),
									sl: { id: "concretePavers" },
								},
								m(
									HarthRadioButton,
									{
										value: "YES",
										size: "small",
										class: "purple-fill",
										id: "concretePavers-yes",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"YES"
								),
								m(
									HarthRadioButton,
									{
										value: "NO",
										size: "small",
										class: "purple-fill",
										id: "concretePavers-no",
										disabled:
											l.get() ||
											u.get() ||
											store.prop("order_status").get() == "Payment Completed",
									},
									"NO"
								)
							)
						)
				  )
				: null,

			store.prop("separatedGarage").get() == "YES" &&
				store.prop("concretePavers").get() &&
				store.prop("workingdistance").get() != 20 &&
				store.prop("propertyowner").get() == "YES"
				? m(
						"",

						m(
							"p." + legacyCSS.leftText,
							`What is the approximate distance between the building of the Main Switch Board and ${store
								.prop("evlocation")
								.get()}?`
						),
						m(
							"",
							m(
								HarthRadioGroup,
								{
									query: store.prop("groundworkingdistance"),
									sl: { id: "groundworkingdistance" },
								},
								[20, 40, 60]
									.filter((gw) => gw <= store.prop("workingdistance").get())
									.map((gw) =>
										m(
											HarthRadioButton,
											{
												value: gw,
												size: "small",
												class: "purple-fill",
												id: "groundworkingdistance-" + gw,
												disabled:
													l.get() ||
													u.get() ||
													store.prop("order_status").get() ==
														"Payment Completed",
											},
											// gw == 20 ? "< 20m" : gw == 60 ? "< 60m" :
											gw + "m"
										)
									)
							)
						)
				  )
				: null,

			store.prop("propertyowner").get() == "NO"
				? m(
						"p." + legacyCSS.leftText,
						`Sorry, we can only enter into agreements with the owner or appointed manager of the property.`
				  )
				: c.get().length >= 1
				? null
				: null
		),
	])
}

function questionsOrderInformation(v: bacta.Vnode, address: boolean) {
	const { store, addressOptions, ValidatedAddress } = useStore()
	const map$ = v.useStore("address-map", v.useStream<Map>())

	v.useEffect({ name: "map-update" }, function* () {
		const [map, FormalAddress] = (yield [
			map$,
			store.prop("FormalAddress"),
		]) as [Map, FormalAddress]

		const icon = L.icon({
			iconUrl: "/marker-icon.png",
			shadowUrl: "/marker-shadow.png",
		})

		if (FormalAddress.position) {
			const marker = L.marker(
				[FormalAddress.position.lat || 0, FormalAddress.position.lng || 0],
				{
					icon,
				}
			)
			marker?.addTo(map)
			map.setView(
				[FormalAddress.position.lat || 0, FormalAddress.position.lng || 0],
				17
			)
		}
	})

	// https://github.com/JAForbes/bacta/issues/4
	v.useEffect({ name: "redraw" }, function* () {
		yield [addressOptions.get, ValidatedAddress.get]
		// console.log("redrawing")
		m.redraw()
	})

	return m(
		".order-information",
		css`
			& {
				display: grid;
				grid-template-columns: minmax(0em, 40em);
				justify-content: space-around;
				padding: var(--content-padding);
			}
		`,
		m(
			"header",
			centeredHeader,
			address ? "Contact Information" : "Your Details",
			m(HarthDivider, { sl: { vertical: false }, centered: true })
		),
		m(
			"",
			css`
				& {
					display: grid;
					gap: var(--content-padding);
				}
			`,
			m(
				HarthInput,
				{
					query: store.prop("order_contact_name"),
					type: "text",
					sl: {
						size: "medium",
						name: "name",
						autocomplete: "name",
						helpText: "Contact Name",
						clearable: false,
						required: false,
						disabled: store.prop("order_status").get() == "Payment Completed",
					},
				},
				m("sl-icon", { name: "person-circle", slot: "prefix" })
			),

			m(
				HarthInput,
				{
					query: store.prop("order_email"),
					type: "email",
					sl: {
						size: "medium",
						name: "email",
						autocomplete: "email",
						helpText: "Email",
						clearable: false,
						required: false,
						disabled: store.prop("order_status").get() == "Payment Completed",
					},
				},
				m("sl-icon", { name: "envelope", slot: "prefix" })
			),
			m(
				"form",
				m(
					HarthInput,
					{
						query: store.prop("order_phone"),
						type: "tel",
						sl: {
							size: "medium",
							name: "tel",
							autocomplete: "tel",
							helpText: "Contact Number",
							clearable: false,
							required: false,
							disabled: store.prop("order_status").get() == "Payment Completed",
						},
					},
					m("sl-icon", { name: "telephone", slot: "prefix" })
				)
			),

			address
				? () =>
						store.prop("deliveryTo").get.map(() =>
							m(
								"form",
								m(
									HarthInput,
									{
										query: store.prop("deliveryTo"),
										type: "text",
										sl: {
											size: "medium",
											autocomplete: "street-address",
											form: "street-address",
											name: "street-address",
											helpText: "Installation Address",
											clearable: false,
											required: false,
											disabled:
												store.prop("order_status").get() == "Payment Completed",
										},
									},
									m("sl-icon", { name: "geo-alt", slot: "prefix" })
								)
							)
						)
				: null,

			m(
				"popup-wrapper",
				css`
					& {
						position: relative;
					}
					& > * {
						position: absolute;
						/* Leaflet :/ */
						z-index: 1000000;
						width: 100%;
					}
				`,
				address
					? () =>
							bacta.Stream.merge([
								store.prop("addressOptions").get as any,
								store.prop("ValidatedAddress").get as any,
							]).map(([,]) =>
								addressOptions.get().length >= 1 &&
								store.prop("deliveryTo").get() !=
									store.prop("ValidatedAddress").get()
									? m(HarthMenu, {
											query: store.prop("FormalAddress") as any,
											options: store.prop("addressOptions").get().slice(0, 10),
											nameProp: "title",
									  })
									: null
							)
					: null
			),

			address
				? () =>
						store
							.prop("FormalAddress")
							.get.map(() =>
								store.prop("FormalAddress").get()?.resultType &&
								store.prop("FormalAddress").get().resultType != "houseNumber"
									? m(
											"." + legacyCSS.leftText,
											{ style: { width: "100%", color: "red" } },
											"Please specify street number"
									  )
									: null
							)
				: null,
			m(
				".map-wrapper",
				css`
					& {
						opacity: ${() =>
							store
								.prop("FormalAddress")
								.prop("position")
								.get.map((x) => (x ? 1 : 0.4))};
						transition: opacity 1s;
						pointer-events: ${() =>
							store
								.prop("FormalAddress")
								.prop("position")
								.get.map((x) => (x ? "inherit" : "none"))};

						border-radius: var(--border-radius);
						overflow: hidden;
						box-shadow: 0px 4px 5px -2px #c1c1c1;
					}
				`,
				m(HarthMap, { id: "address-map", map: map$ })
			),

			() =>
				store.prop("FormalAddress").get.map((f) =>
					!f.address
						? null
						: !restrictedarea.has(Number(f?.address?.postalCode))
						? m(
								"" + legacyCSS.leftText,
								css`
									& {
										color: red;
										font-weight: 500;
									}
								`,
								`We are not currently servicing this area. For any urgent requests, email us at sales@evgen.com.au otherwise we'll be in touch as sson as we start servicing this area `
						  )
						: null
				)
		)
	)
}

function photoInformation(v: bacta.Vnode) {
	const { store, c, l, u } = useStore()

	const requiredPhotos = [
		"1. Pictures of the inside of the Main Switch Board",
		"2. Pictures of the outside of the Main Switch Board",
		"3. Pictures of where the EV Charger will be installed",
		"4. Pictures of the path way between the Main Switch Board and location of the EV Charger",
	]

	setTimeout(() => {
		store.prop("uppy").set(
			m(HarthUppy, {
				getFields: () => ({
					project_id: store.prop("checkoutindex").get()[0].project_id,
					organization_id: "34d166ee-4c95-429c-ba5e-21d079449b2a",
					user_id: "de4a27fb-0b19-4448-a88d-8f10fc4cd276",
				}),
				query: store.prop("files"),
				v,
			})
		)
	}, 500)

	v.useEffect({ name: "photo-counts" }, function* () {
		const { photoquestion, files } = useStore()

		yield [photoquestion, files]

		m.redraw()
	})

	return m(
		".photo-info",
		{},
		m(
			"header",
			{ style: { color: "#a649d9" } },
			centeredHeader,
			"Photos",
			m(HarthDivider, { sl: { vertical: false }, centered: true })
		),

		m(
			"." + legacyCSS.spreadRowsBigGap,
			m(
				"." + legacyCSS.leftText,
				`To help verify the quote, please upload ...`
			),

			// requiredPhotos[store.prop("photoquestion").get()]
			// 	? requiredPhotos.map(
			// 			(a, i) =>
			// 				// m(
			// 				// 	"." + legacyCSS.leftText,
			// 				m(
			// 					"." + legacyCSS.purpletext,
			// 					// (store.prop("photoquestion").get() != i
			// 					// 	? legacyCSS.helper
			// 					// 	: legacyCSS.purpletext),
			// 					a
			// 				)
			// 			// )
			// 	  )
			// 	: m(
			// 			"." + legacyCSS.leftText,
			// 			`any others that you think will be relevant `
			// 	  ),

			m(
				"ul." + legacyCSS.spreadRowsSmallGap,
				css`
					& {
						padding-left: 0px;
					}
					& li {
						list-style: none;
					}
				`,
				requiredPhotos.map((inc) => m("li." + legacyCSS.leftText, inc))
			),

			store.prop("uppy").get()

			// m(
			// 	"." + legacyCSS.spreadRowsCenter,
			// 	store.prop("photoquestion").get() < requiredPhotos.length
			// 		? m(
			// 				HarthButton,
			// 				{
			// 					query: store.prop("photoquestion"),
			// 					sl: {
			// 						size: "small",
			// 						variant: "default",
			// 						outline: true,
			// 						value: store.prop("photoquestion").get() + 1,
			// 						disabled: !store.prop("files").get().length,
			// 					},
			// 				},
			// 				"Next Picture"
			// 		  )
			// 		: ""
			// )
		)
	)
}

function installerPhotoInformation(v: bacta.Vnode) {
	const { store, c, l, u } = useStore()

	return m(".installer-photo-info", [
		m(
			"header",
			css`
				& {
					color: "#a649d9";
				}
			`,
			centeredHeader,
			"Photos",
			m(HarthDivider, { sl: { vertical: false }, centered: true })
		),

		m(
			"." + legacyCSS.spreadRowsBigGap,
			m("." + legacyCSS.centeredtext, `Please upload the below documentation`),

			m(
				"." + legacyCSS.spreadRowsCenter,
				m(HarthUppy, {
					getFields: () => ({
						organization_id: store.prop("organization").get().organization_id,
						user_id: store.prop("user").get().user_id,
						contractor_id: store.prop("contractor").get().contractor_id,
					}),
					v,
				})
			),

			m("." + legacyCSS.leftText, "1. Electrical License"),
			m("." + legacyCSS.leftText, "2. Insurance Certificates"),
			m("." + legacyCSS.leftText, "3. Other")
		),
	])
}

function questionsPricingAndInclusions() {
	const {
		store,
		c,
		l,
		u,
		workingdistance,
		separatedGarage,
		concretePavers,
		groundworkingdistance,
	} = useStore()
	return store.prop("checkoutindex").get().length &&
		store.prop("propertyowner").get() == "YES" &&
		((workingdistance.get() != 0 && separatedGarage.get() == "NO") ||
			(separatedGarage.get() &&
				workingdistance.get() != 0 &&
				concretePavers.get() &&
				groundworkingdistance.get() != 0))
		? m(
				".pricing-and-inclusions",
				css`
					& {
						opacity: ${store.prop("checkoutindex").prop("length").get};
						transition: opacity 500ms;
					}
					& {
						display: grid;
						justify-content: center;
						align-content: center;
						justify-items: center;
						gap: 2em;
					}
				`,
				m(
					HarthRadioGroup,
					{
						query: store.prop("aerial") as any,
						sl: { id: "summary" },
					},
					(store.prop("separatedGarage").get() == "YES" &&
					store.prop("concretePavers").get() == "YES"
						? ["Aerial", "Underground"]
						: ["Underground"]
					).map((a: string, i: number, iarray) =>
						m(
							HarthRadioButton,
							{
								value: iarray.length == 1 ? "NO" : a == "Aerial" ? "YES" : "NO",
								size: "small",
								disabled:
									l.get() ||
									u.get() ||
									store.prop("order_status").get() == "Payment Completed",
							},

							m(
								"." + legacyCSS.spreadRowsSmallGap,
								m(
									".items",
									c.get().map((i) =>
										m(
											"ul." + legacyCSS.spreadRowsSmallGap,
											css`
												& {
													padding-left: 0px;
												}
												& li {
													list-style: none;
												}
											`,
											i.inclusions
												.filter(
													(i) =>
														i.indexOf("Hard Trenching") == -1 &&
														i.indexOf("Aerial Cabling") == -1
												)
												.concat(
													i.baseprice == i.price
														? []
														: a == "Aerial"
														? i.aerialinclusion
														: i.groundinclusion
												)
												.map((inc) => m("li." + legacyCSS.helper, inc))
										)
									)
								),
								m(
									".",
									(a == "Underground" &&
									store.prop("separatedGarage").get() == "NO"
										? "Simple"
										: a) + " Install"
								),
								m(
									"." + legacyCSS.centeredTitles,
									[
										money(
											R.sum(
												c
													.get()
													.map((i) =>
														a == "Aerial"
															? R.prop("baseprice", i) +
															  R.prop("aerialprice", i)
															: R.prop("baseprice", i) +
															  R.prop("groundprice", i)
													)
											),
											0
										),
										" Incl. GST",
									].join(" ")
								)
							)
						)
					)
				),
				m(
					HarthButton,
					{
						id: "sendquote",
						query: store.prop("sendquote"),
						sl: {
							size: "large",
							variant: "default",
							outline: true,
							value: true,
							class: "",
							disabled:
								store.prop("sendquote").get() ||
								(store.prop("checkoutindex").get()[0] || {}).order_welcome,
						},
					},
					"Email me the Quote"
				),
				m(
					".app." + legacyCSS.helper,
					"All quotes are subject to a free site inspection"
				),

				!store.prop("sendquote").get()
					? null
					: m(
							"p." + legacyCSS.leftText,
							{ id: "emailedquote" },
							`This quote has been emailed to you, click next to schedule your obligation free site inspection`
					  )
		  )
		: null
}

async function getPrices(order: string, optoutoforder: string) {
	const {
		store,
		addressError,
		workingdistance,
		separatedGarage,
		concretePavers,
		phase,
		groundworkingdistance,
		propertyowner,
		storey,
		charger,
		evlocation,
		aerial,
		additionals,
		sendquote,
	} = useStore()
	const packages = order
		? []
		: [
				{
					workingdistance: workingdistance.get(),
					separatedGarage: separatedGarage.get(),
					concretePavers: concretePavers.get(),
					additionals: additionals.get(),
					phase: phase.get() || "1",
					groundworkingdistance: groundworkingdistance.get(),
					storey: storey.get(),
					propertyowner: propertyowner.get(),
					charger: charger.get() || 0,
					aerial: aerial.get(),
					evlocation: evlocation.get(),

					order_id: store.prop("order").get()?.order_id,
					order_address: store.prop("ValidatedAddress").get(),
					defaultime: store.prop("dateframe").get()
						? new Date(
								new Date(store.prop("dateframe").get() as Date).setHours(
									store.prop("timeframe").get() == "AM" ? 8 : 13
								)
						  ).toISOString()
						: null,
					timeframe: store.prop("timeframe").get() == "AM" ? "08:00" : "13:00",
					order_referral: store.prop("order_referral").get(),
					order_instructions: store.prop("order_instructions").get(),
					order_contact_name: store.prop("order_contact_name").get(),
					order_phone: store.prop("order_phone").get(),
					order_email: store.prop("order_email").get(),
					order_status: store.prop("order_status").get(),
					user_id: store.prop("user").get().user_id || null,
					scheduleinspection: store.prop("scheduleinspection").get(),
					scheduleinstall: store.prop("scheduleinstall").get(),
				},
		  ]

	if (optoutoforder) {
		await optout(order)
	} else {
		const checkout = (await price(
			order,
			sendquote.get(),
			store.prop("paydeposit").get() ? "install" : "inspection",
			packages
		)) as Array<itemType>
		if (checkout.length) {
			if (checkout[0].order_eta) {
				store.prop("scheduleinspection").update(() => false)
				store.prop("scheduleinstall").update(() => false)
			}

			const defaultime =
				!store.prop("paydeposit").get() &&
				checkout[0].description.inspectiondefaultime
					? new Date(checkout[0].description.inspectiondefaultime).toISOString()
					: store.prop("paydeposit").get() && checkout[0].description.defaultime
					? new Date(checkout[0].description.defaultime).toISOString()
					: new Date(checkout[0].nextavailable).toISOString() || null
			const autodescription = checkout.map((d) => d.order_id || "")
			const description = store.prop("order_description").get()
			store
				.prop("order_description")
				.update(() =>
					checkout.length > 1 ? description : autodescription.join(", ")
				)

			if (!store.prop("dateframe").get() || store.prop("paydeposit").get()) {
				store
					.prop("dateframe")
					.update(() => defaultime?.slice(0, 10) as unknown as Date | null)
			}

			store
				.prop("timeframe")
				.update(() =>
					[
						defaultime?.slice(11, 13) as unknown as string | null,
						":",
						"00",
					].join("") == "08:00"
						? "AM"
						: "PM"
				)
		}

		store.prop("checkoutindex").update(() => checkout)

		if (checkout?.some((e) => e.error)) {
			const error = R.uniq(checkout.map((e) => e.error))
				.filter(R.identity)
				.join(",")

			addressError.update(() => error)
			writeToLoad(false)
		} else {
			addressError.update(() => "")
			store.prop("orders").update(() => checkout)
			store.prop("order").update(() => checkout[0])
		}

		const o = checkout[0]
		const s = o.order_status
		const outstanding = o.price - o.paid
		store.prop("outstanding").update(() => outstanding)
		if (order && checkout.length) {
			// change route to correct step

			const steps = [
				"details",
				"details-confirmation",
				"payment",
				"photo-uploads",
				"thankyou",
			]

			const faddress = await here(o.order_address)
			store
				.prop("FormalAddress")
				.update(() => faddress[0] || ({} as FormalAddress))

			const step =
				s == "Started Order" || s == "" || s == "Contact Only"
					? "details"
					: s == "Contact Details Acquired"
					? "details-confirmation"
					: s == "Photos Acquired"
					? "photo-uploads"
					: s == "Payment Attempted" ||
					  s == "Order Details Acquired" ||
					  outstanding > 0
					? "payment"
					: "thankyou"

			store.prop("ValidatedAddress").update(() => o.order_address || "")
			store.prop("order_contact_name").update(() => o.order_contact_name)
			store.prop("order_phone").update(() => o.order_phone)
			store.prop("order_email").update(() => o.order_email)
			store.prop("order_description").update(() => o.order_description)
			store.prop("order_status").update(() => o.order_status)
			store.prop("order_instructions").update(() => o.order_instructions)
			store.prop("order_referral").update(() => o.order_referral)
			store.prop("order_referrer").update(() => o.order_referrer)

			store.prop("charger").update(() => o.description.charger)
			store.prop("separatedGarage").update(() => o.description.separatedGarage)
			store.prop("additionals").update(() => o.description.additionals)
			store.prop("concretePavers").update(() => o.description.concretePavers)
			store.prop("phase").update(() => o.description.phase)
			store.prop("workingdistance").update(() => o.description.workingdistance)
			store
				.prop("groundworkingdistance")
				.update(() => o.description.groundworkingdistance)
			store.prop("storey").update(() => o.description.storey)
			store.prop("propertyowner").update(() => o.description.propertyowner)
			store.prop("aerial").update(() => o.description.aerial as "YES" | "NO")
			store.prop("evlocation").update(() => o.description.evlocation)
			store.prop("deliveryTo").update(() => o.order_address || "")

			for (let i = 0; i < steps.length; i++) {
				const sroute = steps[i]
				if (sroute === step) {
					break
				}
				// console.log(step, window.location.origin + "/questions/" + sroute)
				history.pushState(
					"setorder",
					"",
					window.location.origin + "/questions/" + sroute
				)
			}

			m.route.set(window.location.origin + "/questions/" + step)
		}
	}

	writeToLoad(false)
	return Promise.resolve()
}

async function getOrders() {
	writeToLoad(true)
	await getPrices(m.route.param("order_id"), m.route.param("optout"))
	writeToLoad(false)
}

async function getAddresses() {
	const { d, store } = useStore()
	if (d.get() != store.prop("ValidatedAddress").get()) {
		const addresses = await here(d.get())
		store.prop("addressOptions").update(() => addresses as object[])
	}
}
const pricingInterval = 700
const typingInterval = 500
const machineInterval = 200
const debounceAddress = debounce(getAddresses, typingInterval)
const debouncePrices = debounce(getPrices, pricingInterval)
const debounceOrders = debounce(getOrders, machineInterval)
const debounceConsent = debounce(patchConsent, machineInterval)

function writeToLoad(x: boolean) {
	const { l } = useStore()
	l.get() != x ? l.update(() => x) : null
}

function useAddress(v: bacta.Vnode) {
	v.useEffect({ name: "address" }, function* () {
		const { d, ValidatedAddress } = useStore()

		yield [d, ValidatedAddress]

		d.get() ? debounceAddress() : []
	})
}

function setAddress(v: bacta.Vnode) {
	v.useEffect({ name: "setaddress" }, function* () {
		const { ValidatedAddress, d, FormalAddress, store } = useStore()
		yield FormalAddress

		if (FormalAddress.get().title) {
			d.update(() => FormalAddress.get().title || "")
			if (
				FormalAddress.get().resultType == "houseNumber" ||
				FormalAddress.get().resultType == "place"
			) {
				ValidatedAddress.update(() => FormalAddress.get().title || "")
				store.prop("addressOptions").update(() => [])
			}
		}
	})
}

function useSearchItems(v: bacta.Vnode) {
	v.useEffect({ name: "search-items" }, function* () {
		const { name, itemSearch } = useStore()

		yield [name, itemSearch]

		// writeToLoad(true)
		// todo-james we don't really need the debounce
		// could do the debounce in useEffect instead
		// debounceSearchSites(store, s)
	})
}

function useSearchCategories(v: bacta.Vnode) {
	v.useEffect({ name: useSearchCategories.name }, function* () {
		const { name: n } = useStore()

		yield [n]

		// writeToLoad(true)
		// debounceSearchServices(store, yield s)
	})
}

function updatedSepratedGarage(v: bacta.Vnode) {
	v.useEffect({ name: updatedSepratedGarage.name }, function* () {
		const { separatedGarage, workingdistance, groundworkingdistance } =
			useStore()

		yield [separatedGarage, workingdistance]

		if (separatedGarage.get() == "NO") {
			groundworkingdistance.update(() => 0)
		} else if (separatedGarage.get() == "YES" && workingdistance.get() == 20) {
			groundworkingdistance.update(() => 20)
		}
	})
}

function usePricingAutoTab(v: bacta.Vnode) {
	v.useEffect({ name: usePricingAutoTab.name }, function* () {
		const {
			s,
			separatedGarage,
			concretePavers,
			phase,
			workingdistance,
			groundworkingdistance,
			storey,
			propertyowner,
			aerial,
			charger,
			c,
			dateframe,
			timeframe,
			ValidatedAddress,
			order_instructions,
			order_referral,
			order_contact_name,
			order_phone,
			order_email,
			order_status,
			sendquote,
			scheduleinspection,
			scheduleinstall,
			paydeposit,
		} = useStore()

		yield [
			separatedGarage,
			concretePavers,
			phase,
			workingdistance,
			groundworkingdistance,
			storey,
			propertyowner,
			aerial,
			charger,
			dateframe,
			timeframe,
			ValidatedAddress,
			order_instructions,
			order_referral,
			order_contact_name,
			order_phone,
			order_email,
			order_status,
			sendquote,
			scheduleinspection,
			scheduleinstall,
			paydeposit,
		]

		const formfileld = true
		// order_status.get() != "Order Details Acquired" ||
		// (workingdistance.get() != 0 && separatedGarage.get() == "NO") ||
		// (separatedGarage.get() &&
		// 	workingdistance.get() != 0 &&
		// 	concretePavers.get() &&
		// 	groundworkingdistance.get() != 0)

		const [state] = (yield [s]) as [State["state"]]

		const tab = document.querySelector("sl-tab-group")
		if ((tab || state == "") && formfileld) {
			// tab.show("Checkout")
			writeToLoad(true)
			debouncePrices()
		} else if (tab && state == "Search") {
			tab.show("Search")
		} else if (!formfileld) {
			c.update(() => [])
		}
	})
}

function useOrdersAutoTab(v: bacta.Vnode) {
	v.useEffect({ name: useOrdersAutoTab.name }, function* () {
		const { s, o } = useStore()
		const state = (yield s) as ReturnType<typeof s.get>
		const order = (yield o) as ReturnType<typeof o.get>
		const tab = document.querySelector("sl-tab-group")
		if (tab && state == "Home" && order == "") {
			tab.show("Home")
			// console.log("hit orders")
			// writeToLoad(true)
			// debounceOrders()
		}
	})
}

m.route.prefix = ""

const RegisterInterest = bacta.component((v) => {
	const { store } = useStore()
	const dealer = m.route.get()
	return () =>
		m(
			".register-interest",
			css`
				& {
					display: grid;
					gap: var(--input-gap);

					padding: var(--content-padding);
				}
			`,
			dealer.indexOf("dealer") == -1
				? null
				: m(
						HarthInput,
						{
							query: store.prop("order_referral"),
							type: "text",
							sl: {
								size: "medium",
								helpText: "Dealer Code",
								clearable: false,
								required: false,
								disabled:
									store.prop("order_status").get() == "Payment Completed",
							},
						},
						m("sl-icon", { name: "hash", slot: "prefix" })
				  ),

			dealer.indexOf("dealer") == -1
				? null
				: m(
						HarthInput,
						{
							query: store.prop("order_referrer"),
							type: "text",
							sl: {
								size: "medium",
								helpText: "Salesman",
								clearable: false,
								required: false,
								disabled:
									store.prop("order_status").get() == "Payment Completed",
							},
						},
						m("sl-icon", { name: "person-up", slot: "prefix" })
				  ),
			m(
				HarthInput,
				{
					query: store.prop("order_contact_name"),
					type: "text",
					sl: {
						size: "medium",
						name: "name",
						autocomplete: "name",
						helpText: "Contact Name",
						clearable: false,
						required: false,
						disabled: store.prop("order_status").get() == "Payment Completed",
					},
				},
				m("sl-icon", { name: "person-circle", slot: "prefix" })
			),

			m(
				"form",
				m(
					HarthInput,
					{
						query: store.prop("order_phone"),
						type: "tel",
						sl: {
							size: "medium",
							name: "tel",
							autocomplete: "tel",
							helpText: "Contact Number",
							clearable: false,
							required: false,
							disabled: store.prop("order_status").get() == "Payment Completed",
						},
					},
					m("sl-icon", { name: "telephone", slot: "prefix" })
				)
			),

			m(
				HarthInput,
				{
					query: store.prop("order_email"),
					type: "email",
					sl: {
						size: "medium",
						name: "email",
						autocomplete: "email",
						helpText: "Email",
						clearable: false,
						required: false,
						disabled: store.prop("order_status").get() == "Payment Completed",
					},
				},
				m("sl-icon", { name: "envelope", slot: "prefix" })
			),
			m(
				HarthButton,
				{
					query: store.prop("order_status"),
					postop: getPrices,
					sl: {
						size: "large",
						variant: "default",
						outline: false,
						value: "Contact Only",
						class: "yellowbackground",
						disabled: false,
					},
				},
				"Register"
			)
		)
})

const Welcome = bacta.component((v) => {
	const { store } = useStore(v)

	if (m.route.param("ref")) {
		store.prop("order_referral").update(() => m.route.param("ref") || "")
		m.route.set("/questions/welcome/")
	}

	debounceOrders()

	return () => {
		const splashImg = m(
			".splash-img",
			css`
				& {
					background: transparent url("assets/landing-lamboghini.jpg") center
						center / cover no-repeat;
					border-radius: var(--border-radius);
				}

				@media (min-aspect-ratio: 1/1) {
					& {
						min-height: 50dvh;
					}
				}
				@media (max-aspect-ratio: 1/1) {
					& {
						min-height: 30dvh;
					}
				}
			`
		)

		const form = [
			m(
				".intro",
				css`
					& {
						display: grid;
						gap: 3em;
						padding: var(--content-padding);
					}
				`,
				splashImg,

				m(
					"p",
					css`
						@media (min-aspect-ratio: 1/1) {
							& {
								max-width: 80%;
								justify-self: center;
							}
						}
						& {
							display: grid;
							gap: 6em;
						}
					`,

					m(
						"p",
						css`
							& {
								display: grid;
								gap: 2em;
							}
						`,
						m(
							"." + legacyCSS.centeredtext,
							`Welcome to EVGEN, where we've revolutionised the EV charger buying experience.`
						),
						m(
							"." + legacyCSS.centeredtext,
							`Many customers face the frustration of finding an electrician after purchasing their EV charger.
						EVGEN simplifies the process by offering a `,
							m("em.i." + legacyCSS.leftTitles, `One-Stop Solution.`)
						),
						m(
							"." + legacyCSS.centeredtext,
							`Purchase your EV charger and we'll match you with a fully licensed local electrician for hassle-free installation.`
						),
						m(
							"." + legacyCSS.spreadRowsCenter,
							m(
								Link,
								{
									href: "/questions/welcome",
									size: "medium",
									variant: "primary",
									id: "get_quoted",
									outline: true,
									value: "Started Order",
									class: "purpleBorder",
									onclick(e: any) {
										e.preventDefault()
										store.prop("order_status").update(() => "")
										m.route.set(e.currentTarget.href)
									},
								},
								"Get Quoted"
							)
						)
					),

					m(
						".",
						css`
							& {
								display: grid;
								gap: 2em;
							}
						`,
						m(
							"." + legacyCSS.centeredHeader,
							"Why EVGEN",
							m(HarthDivider, {
								sl: { vertical: false },
								centered: true,
							})
						),
						m(
							"p",
							css`
								& {
									display: grid;
									gap: 0.51em;
								}
							`,

							m(
								"." + legacyCSS.centeredtext,
								`At EVGEN, we understand the challenges of EV charger installation. Our goal is to make the entire process as smooth as possible. No more searching for electricians or dealing with complicated installations. EVGEN has you covered.`
							)
						)
					),

					m(
						".",
						css`
							& {
								display: grid;
								gap: 2em;
							}
						`,
						m(
							"." + legacyCSS.centeredHeader,
							"Benefits",
							m(HarthDivider, {
								sl: { vertical: false },
								centered: true,
							})
						),

						m(
							".",
							[
								`Instant Quote: Get a quote instantly by filling in your details.`,
								`Simple Platform: Purchase your EV charger with ease.`,
								`Professional Installation: Matched with a licensed electrician.`,
							].map((d) =>
								m(
									"ul." + legacyCSS.leftText,
									css`
										& {
											padding-right: 40px;
										}
									`,
									m(
										"." + legacyCSS.spreadColumnsAlignStart,
										m(HarthIcon, {
											sl: {
												name: "check-circle",
												slot: "suffix",
												style: { "font-size": "18px", color: "#a649d9" },
											},
										}),
										m("li." + legacyCSS.leftText, d)
									)
								)
							)
						)
					),

					m(
						".",
						css`
							& {
								display: grid;
								gap: 2em;
							}
						`,
						m(
							"." + legacyCSS.centeredHeader,
							"How It Works",
							m(HarthDivider, {
								sl: { vertical: false },
								centered: true,
							})
						),

						m(
							".",
							m(
								"p",
								css`
									& {
										display: grid;
										gap: 1em;
									}
								`,
								m(
									"." + legacyCSS.centeredtext,
									`Tell us a bit about your property so we can schedule your installation as soon as possible`
								),
								m(
									"." + legacyCSS.centeredtext,
									`Once we have all the necessary details, our platform will send you a quote and you’ll be provided with different payment options.`
								),
								m(
									"." + legacyCSS.spreadRowsCenter,
									m(
										Link,
										{
											href: "/questions/welcome",
											size: "medium",
											variant: "primary",
											id: "get_started",
											outline: true,
											value: "Started Order",
											class: "purpleBorder",
											onclick(e: any) {
												e.preventDefault()
												store.prop("order_status").update(() => "")
												m.route.set(e.currentTarget.href)
											},
										},
										"Get Started Today"
									)
								)
							)
						),

						store.prop("order_status").get() === "Contact Only"
							? m(RegisterInterest)
							: null
					),

					m(
						".",
						css`
							& {
								display: grid;
								gap: 0.5em;
							}
						`,
						m(
							"." + legacyCSS.centeredHeader,
							"Questions",
							m(HarthDivider, {
								sl: { vertical: false },
								centered: true,
							})
						),
						m(
							"p",
							css`
								& {
									display: grid;
									gap: 1em;
								}
							`,
							m(
								"." + legacyCSS.centeredtext,
								`Feel free to contact us at hello@evgen.com.au if you require assistance. Our team of experts will be more than happy to help!`
							),

							m(
								".",
								m(
									"p",
									css`
										& {
											display: grid;
											gap: 1em;
										}
									`,

									m(
										"." + legacyCSS.spreadRowsCenter,
										m(
											HarthButton,
											{
												query: store.prop("order_status"),
												sl: {
													size: "medium",
													variant: "primary",
													outline: true,
													value: "Contact Only",
													disabled: false,
													class: "purpleBorder",
												},
											},
											"Contact Us"
										)
									)
								)
							)
						)
					)
				)
			),
		]
		return m(FormLayout, form)
	}
})

const Goodbye = bacta.component((v) => {
	const { store } = useStore(v)

	debounceOrders()

	return () => {
		const splashImg = m(
			".splash-img",
			css`
				& {
					background: transparent url("/assets/landing-lamboghini.jpg") center
						center / cover no-repeat;
					border-radius: var(--border-radius);
				}

				@media (min-aspect-ratio: 1/1) {
					& {
						min-height: 50dvh;
					}
				}
				@media (max-aspect-ratio: 1/1) {
					& {
						min-height: 30dvh;
					}
				}
			`
		)

		const form = [
			m(
				".intro",
				css`
					& {
						display: grid;
						gap: 3em;
						padding: var(--content-padding);
					}
				`,
				splashImg,

				m(
					"p",
					css`
						@media (min-aspect-ratio: 1/1) {
							& {
								max-width: 80%;
								justify-self: center;
							}
						}
						& {
							display: grid;
							gap: 6em;
						}
					`,

					m(
						"p",
						css`
							& {
								display: grid;
								gap: 2em;
								align-content: center;
							}
						`,
						m(
							"." + legacyCSS.centeredtext,
							`Goodbye from EVGEN, we are sad to see you leave.`
						),

						m(
							"." + legacyCSS.centeredtext,
							`Your quote will still be accessible for 4 weeks if you decide to re-engage us.`
						),
						m("." + legacyCSS.centeredtext, [
							`If you require any help or would like to speak more feel free to reach out to us to at `,
							m(
								"a",
								{ href: "mailto:sales@evgen.com.au" },
								"sales@evgen.com.au"
							),
						])
					)
				)
			),
		]
		return m(FormLayout, form)
	}
})

const FormLayout = bacta.component(() => {
	return (v) =>
		m(
			".inner-layout",
			css`
				@media (min-aspect-ratio: 1/1) {
					& {
						display: grid;
						gap: 1em;

						grid-template-columns: minmax(0em, 50em);
						justify-content: center;
						align-items: center;
						gap: 1em;
					}

					& .form {
						display: grid;

						background-color: var(--form-background-color);
						border-radius: var(--border-radius);

						/* desktop only */
						box-shadow: var(--card-shadow-color) 0px 8px 9px -9px;
					}
				}

				@media (max-aspect-ratio: 1/1) {
					& {
						display: grid;
						align-items: start;
						background-color: white;
					}

					& .form {
						display: grid;
						height: 100%;
						gap: 1em;
					}
				}
			`,
			m(".form", v.children)
		)
})

const Nav = bacta.component(() => {
	return () =>
		m(
			"nav.",
			css`
				& {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					align-content: space-around;

					background-color: white;
					border-bottom: solid 1px #e6e6e6;
					align-items: center;
					padding: var(--nav-padding);
					width: 100%;

					box-sizing: border-box;
				}

				& > *:nth-child(2) {
					justify-self: center;
				}
				& > *:last-child {
					justify-self: end;
				}

				& img.logo {
					height: calc(var(--nav-height) - (var(--nav-padding) * 2));
				}
			`,
			m(".spacer"),
			m("img.logo", {
				src: dologo,
			}),

			// eslint-disable-next-line no-constant-condition
			true
				? m(".spacer")
				: m(
						Link,
						{
							href: "/installer",
							size: "small",
							variant: "default",
							outline: false,
							class: "whitebackground",
							onclick(e: any) {
								e.preventDefault()
								m.route.set(e.currentTarget.href)
							},
						},
						"LOGIN / BECOME AN INSTALLER"
				  )
		)
})

const Layout = bacta.component<
	{ comp: bacta.ComponentTypes } & bacta.Attributes
>((v) => {
	// same store is retained across routes
	const { store, l, u } = useStore(v)
	Object.assign(window, { store, m })
	return ({ attrs: { comp, ...attrs } }) =>
		m(
			".app",
			(console.log("redraw"), null),
			css`
				#app.theme-light {
					--form-background-color: white;
					--app-background-color: #eeeeee;
					--footer-color: #e3e3e3;
					--font-color: black;
					--status-text-color: #7a62ae;

					--dot-shadow-color: #5c22642e;
					--card-shadow-color: #5c22642e;
				}
				#app {
					display: grid;
					min-height: 100dvh;
					margin: 0em;
					padding: 0em;
					box-sizing: border-box;

					--z-index-nav: 1;

					--nav-height: 2.5em;
					--nav-padding: 0.5em;

					--border-radius: 0.25em;
					--content-padding: 1em;

					--content-height: calc(100dvh - var(--nav-height));
					--content-height: auto;

					--input-gap: 1em;

					background-color: var(--app-background-color);

					font-family: "Lato", "-apple-system", "BlinkMacSystemFont", "Segoe UI",
						"Roboto", Helvetica, Arial, sans-serif, "Apple Color Emoji",
						"Segoe UI Emoji", "Segoe UI Symbol";

					color: var(--font-color);
				}

				& {
					display: grid;
					grid-template-rows: var(--nav-height) var(--content-height);
				}

				& .content {
					display: grid;
				}

				bacta-css-node {
					display: none;
				}

				h1,
				h2,
				h3,
				h4,
				h5,
				h6,
				p {
					margin: 0em;
				}
			`,
			m(Nav),
			m(
				".content",
				css`
					& {
						display: grid;
					}
				`,
				m(comp, attrs)
			),
			l.get() || u.get()
				? m(
						".fixed-spinner",
						css`
							& {
								position: fixed;
								bottom: 2rem;
								right: 2rem;

								text-align: center;
								align-items: center;
							}
						`,
						m(
							"",
							m(HarthSpinner, {
								size: "4rem",
								color: "#bb73e2",
							})
						)
				  )
				: null
		)
})

type WizardStep = {
	component: bacta.BactaComponent
	id: string
	prev?: {
		text?: string
		action?(): void
	}
	next?: {
		text?: string
		action?(): void
		disabled?: boolean
	}
}

const WizardProgressDots = bacta.component<{
	activeId: string
	steps: WizardStep[]
	statusText: () => bacta.Children
}>((v) => {
	return ({ attrs }) => {
		const seenAt = attrs.steps.findIndex((x) => x.id === attrs.activeId)
		return m(
			".wizard-progress-dots",
			css`
				& {
					display: grid;
					gap: 0.7em;
					justify-items: center;
				}
				& .completion {
					display: grid;
					grid-auto-flow: column;
					justify-content: start;
					align-items: center;
					gap: 0.9em;
				}
				& .dot {
					width: 1em;
					height: 1em;
					border-radius: 100%;

					box-shadow: inset var(--dot-shadow-color) 0px 2px 4px -1px;
				}

				& .dot.active {
					--background-color: rgb(193, 150, 255);
					--scale: 1.4;
				}
				& .dot {
					--background-color: rgba(136, 38, 255, 0.5);
					--background-color: rgb(214, 186, 255);
					--scale: 1;

					background-color: var(--background-color);
					width: 0.5em;
					height: 0.5em;
					transform: scale(var(--scale), var(--scale));
					transition: 100ms;
					box-sizing: border-box;
				}
			`,
			m(
				".completion",
				attrs.steps.map((_, i) => {
					return m(".dot" + (i <= seenAt ? ".active" : ""))
				})
			),
			attrs.statusText()
		)
	}
})

const Wizard = bacta.component<{ activeId: string; steps: WizardStep[] }>(
	(v) => {
		let askForExit = false
		const { store, l, u, order_status } = useStore()

		// these run every route change within the wizard
		useSearchItems(v)
		useSearchCategories(v)
		usePricingAutoTab(v)
		updatedSepratedGarage(v)
		useOrdersAutoTab(v)
		useAddress(v)
		setAddress(v)

		v.useEffect({ name: "initialize" }, function* () {
			// this early exits internally if already run
			// yield initialize(store)
		})

		v.useEffect({ name: "spinner-redraw" }, function* () {
			yield [u.get, l.get]
			m.redraw()
		})

		v.useEffect({ name: "status-update" }, function* () {
			const [value] = yield [
				v.route,
				store.prop("separatedGarage"),
				store.prop("concretePavers"),
				store.prop("phase"),
				store.prop("workingdistance"),
				store.prop("groundworkingdistance"),
				store.prop("storey"),
				store.prop("propertyowner"),
				store.prop("aerial"),
				store.prop("charger"),
				store.prop("dateframe"),
				store.prop("timeframe"),
				store.prop("ValidatedAddress"),
				store.prop("order_instructions"),
				store.prop("order_referral"),
				store.prop("order_contact_name"),
				store.prop("order_phone"),
				store.prop("order_email"),
				store.prop("sendquote"),
			]

			const status = order_status.get()

			order_status.update(() => {
				const notvalid =
					store.prop("verifiedstep").get()[value]() ||
					store.prop("verifiedstep").get()[value]() == null
				const newstatus =
					status == "Payment Completed"
						? status
						: value == "/questions/details"
						? "Started Order"
						: value == "/questions/details-confirmation"
						? "Contact Details Acquired"
						: value == "/questions/payment"
						? "Order Details Acquired"
						: value == "/questions/photo-uploads"
						? "Photos Required"
						: status

				return notvalid ? newstatus : status
			})

			if (store.prop("PaymentError").get()) {
				order_status.update(() => "Payment Attempted")
			}

			m.redraw()
		})

		return ({ attrs: { steps, activeId } }) => {
			const activeStepIndex = steps.findIndex((x) => x.id === activeId)!
			const activeStep = steps[activeStepIndex]
			const prevStep = [steps[activeStepIndex - 1]].filter((x) => x != null)
			const nextStep = [steps[activeStepIndex + 1]].filter((x) => x != null)

			const [[prevLink], [nextLink]] = [prevStep, nextStep].map((step) =>
				step.map((x) => m.route.get().replace("/" + activeStep.id, "/" + x.id))
			)

			return m(
				".wizard",
				css`
					& {
						display: grid;
						--h: 80vh;
						grid-template-rows: minmax(var(--h), 100%) max-content;
					}
					& .content {
						padding: var(--content-padding);
					}
					& .footer {
					}
					@media (max-aspect-ratio: 1/1) {
						& {
							grid-template-rows: 1fr max-content;
						}
					}
				`,
				m("main.content", m(activeStep?.component)),
				m(
					"footer.footer",
					css`
						& {
							display: grid;

							grid-template-columns: minmax(7em, max-content) max-content minmax(
									7em,
									max-content
								);
							justify-content: space-between;
							align-items: center;
							gap: var(--input-gap);

							background-color: var(--footer-color);
							border-radius: 0em 0em var(--border-radius) var(--border-radius);
							padding: var(--content-padding);
						}
					`,
					m(
						Dialog,
						{ open: askForExit, label: "Exit online quote?" } as DialogAttrs,
						m(
							".content",
							css`
								& {
									display: grid;
									gap: 1em;
								}
							`,
							m(
								"p." + legacyCSS.leftText,
								"Are you sure you want to exit the online quote?"
							),
							m(
								".buttons",
								css`
									& {
										display: grid;
										gap: 1em;
										grid-auto-flow: column;
									}
								`,
								m(
									Button,
									{
										onclick() {
											// todo-james if we use m.route here, the spinner loads forever?
											m.route.set("/welcome")
										},
										variant: "danger",
									},
									"Yes"
								),
								m(
									Button,
									{
										onclick() {
											askForExit = false
										},
									} as ButtonAttrs,
									"No, I want to continue"
								)
							)
						)
					),
					m(
						Link,
						{
							href: prevLink ?? m.route.get(),
							disabled: l.get(),
							onclick(e) {
								e.preventDefault()
								if (!prevLink) {
									askForExit = true
									console.log("onclick", { askForExit })
									m.redraw()
								} else {
									window.history.back()
								}
							},
						},
						!prevLink ? "Home" : "Back"
					),
					m(WizardProgressDots, {
						steps,
						activeId,
						statusText: () =>
							m(
								"p",
								css`
									& {
										color: var(--status-text-color);
									}
								`
								// pretty(activeStep.id, {
								// 	"details-confirmation": "Contact Details",
								// 	details: "Details of Installation",
								// })
							),
					}),
					!activeStep.next
						? null
						: m(
								Link,
								{
									href: nextLink ?? m.route.get(),
									id: activeStep.id,
									disabled:
										(!nextLink && !activeStep.next?.action) ||
										!!nextStep.find((x) => x.next?.disabled),
									onclick: (e) => {
										e.preventDefault()
										if (
											nextLink &&
											!(
												(!nextLink && !activeStep.next?.action) ||
												!!nextStep.find((x) => x.next?.disabled)
											)
										) {
											m.route.set(nextLink)
										}
										activeStep.next?.action?.()
									},
								},
								!nextLink ? "Done" : "Next"
						  )
				)
			)
		}
	}
)

const QuestionsWelcome = bacta.component(() => () => {
	const splashImg = m(
		".splash-img",
		css`
			& {
				background: transparent url("/assets/evgen-bg-2.png") center center /
					cover no-repeat;
				border-radius: var(--border-radius);
			}

			@media (min-aspect-ratio: 1/1) {
				& {
					min-height: 50dvh;
				}
			}
			@media (max-aspect-ratio: 1/1) {
				& {
					min-height: 30dvh;
				}
			}
		`
	)
	return m(
		".QuestionsWelcome",
		css`
			& {
				display: grid;
				align-content: start;
				gap: var(--input-gap);
			}
		`,
		m("h1", centeredHeader, "Welcome"),
		m(
			".",
			css`
				& {
					display: grid;
					justify-items: center;
				}
			`,

			m("img", {
				src: plug,
				style: {
					width: "30%",
					"align-items": "center",
				},
			})
		),
		m(
			"." + legacyCSS.centeredTitles,
			css`
				& {
					color: #a649d9;
				}
			`,
			`Let’s Get Started`
		),

		m(
			"p",
			css`
				& {
					display: grid;
					gap: 1em;
					align-items: center;
					justify-items: center;
					padding: 1em;
				}
			`,

			m(
				".",
				css`
					& {
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 2em;
					}
				`,
				m(
					".",
					css`
						& {
							display: flex;
							flex-direction: column;
							align-items: center;
						}
					`,

					m("img", {
						src: introstep1,
						style: {
							width: "50%",
							"align-items": "center",
							"object-fit": "scale-down",
						},
					}),

					m(
						"." + legacyCSS.centeredHeader,
						css`
							& {
								color: #a649d9;
							}
						`,
						`Customer Info`
					),
					m(
						"." + legacyCSS.centeredtext,
						css`
							& {
								color: #a649d9;
							}
						`,
						`Some basic contact information required to generate the quote and ensure you receive it.`
					)
				),
				m("img", {
					src: varrow,
					style: {
						width: "25%",
						transform: "rotate(90deg)",
						"align-items": "center",
						"object-fit": "scale-down",
					},
				}),

				m(
					".",
					css`
						& {
							display: flex;
							flex-direction: column;
							align-items: center;
						}
					`,

					m("img", {
						src: introstep2,
						style: {
							width: "50%",
							"align-items": "center",
							"object-fit": "scale-down",
						},
					}),
					m(
						"." + legacyCSS.centeredHeader,
						css`
							& {
								color: #a649d9;
							}
						`,
						`Generate Quote`
					),
					m(
						"." + legacyCSS.centeredtext,
						css`
							& {
								color: #a649d9;
							}
						`,
						`A few questions about your property so we can accurately provide you with an instant quote.`
					)
				),

				m("img", {
					src: varrow,
					style: {
						width: "25%",
						transform: "rotate(90deg)",
						"align-items": "center",
						"object-fit": "scale-down",
					},
				}),

				m(
					".",
					css`
						& {
							display: flex;
							flex-direction: column;
							align-items: center;
						}
					`,
					m("img", {
						src: introstep3,
						style: {
							width: "50%",
							"align-items": "center",
							"object-fit": "scale-down",
						},
					}),
					m(
						"." + legacyCSS.centeredHeader,
						css`
							& {
								color: #a649d9;
							}
						`,
						`Book Inspection`
					),
					m(
						"." + legacyCSS.centeredtext,
						css`
							& {
								color: #a649d9;
							}
						`,
						`Schedule a site inspection at a time that suits you for an obligation free site inspection`
					),
					m("br"),
					m(
						"." + legacyCSS.centeredtext,
						css`
							& {
								color: #a649d9;
							}
						`,
						` These inspections are important to confirm your quote and to ensure the installation will meet safety requirement and design standards`
					)
				)
			)
		)
	)
})

const printMe = async () => {
	window.print()
	return true
}

const QuestionDetails = bacta.component(
	(v) => () =>
		m(
			".QuestionDetails",
			css`
				& {
					display: grid;
					align-items: start;
					gap: var(--input-gap);

					max-height: 80dvh;
					overflow-y: auto;
				}
			`,
			questionsInstallInformation(v),
			questionsPricingAndInclusions()
		)
)

const PhotoDetails = bacta.component(
	(v) => () =>
		m(
			".PhotoDetails",
			css`
				& {
					display: grid;
					align-items: start;
					gap: var(--input-gap);

					max-height: 80dvh;
					overflow-y: auto;
				}
			`,
			photoInformation(v)
		)
)

const QuestionConfirmation = bacta.component(
	(v) => () =>
		m(
			".QuestionDetails",
			css`
				& {
					display: grid;
					overflow-y: auto;
					align-items: start;
					gap: var(--input-gap);

					max-height: 80dvh;
					overflow-y: auto;
				}
			`,
			questionsOrderInformation(v, true)
		)
)

const QuestionPayment = bacta.component(
	(v) => () =>
		m(
			".QuestionPayment",
			css`
				& {
					display: grid;
					overflow-y: auto;
					align-items: start;
					gap: var(--input-gap);

					max-height: 80dvh;
					overflow-y: auto;
				}
			`,
			questionsPaymentInputs(v)
		)
)
const QuestionsThankYou = bacta.component(() => () => {
	const { store, c, l, u } = useStore()
	const splashImg = m(
		".splash-img",
		css`
			& {
				background: transparent url("/assets/evgen-bg-1.png") center center /
					cover no-repeat;
				border-radius: var(--border-radius);
			}

			@media (min-aspect-ratio: 1/1) {
				& {
					min-height: 50dvh;
				}
			}
			@media (max-aspect-ratio: 1/1) {
				& {
					min-height: 30dvh;
				}
			}
		`
	)

	const qtags = [
		{
			a: "charger",
			c: true,
			q: `Tesla EV Charger`,
		},
		{
			a: "phase",
			c: true,
			q: `phase electrical connection or lower`,
		},
		{
			a: "evlocation",
			c: true,
			q: `installed Tesla Charger`,
		},
		{
			a: "storey",
			c: true,
			q: `storey property`,
		},
		{
			a: "propertyowner",
			c: true,
			q: `${store
				.prop("order_contact_name")
				.get()} is the owner or manager of the property`,
		},
		{
			a: "workingdistance",
			c: true,
			q: `m is the approximate distance between the ${store
				.prop("evlocation")
				.get()} and the Main Switch Board`,
		},
		{
			a: "separatedGarage",
			c: true,
			q: `the 
				${store.prop("evlocation").get()}
				${store.prop("separatedGarage").get() == "YES" ? " is " : " is not "}
				is detached from the Main Switch Board`,
		},
		{
			a: "concretePavers",
			c: store.prop("separatedGarage").get() == "YES",
			q: `there ${
				store.prop("concretePavers").get() == "YES" ? "are" : "are not"
			} 
				Concrete or Pavers are between the Main Switch Board and ${store
					.prop("evlocation")
					.get()}`,
		},
		{
			a: "groundworkingdistance",
			c:
				store.prop("separatedGarage").get() == "YES" &&
				store.prop("concretePavers").get() &&
				store.prop("workingdistance").get() != 20,
			q: `m is the approximate distance between the Main Switch Board and the ${store
				.prop("evlocation")
				.get()}`,
		},
	]
	return m(
		".QuestionsThankyou",
		css`
			& {
				display: grid;
				gap: var(--input-gap);
			}
		`,
		splashImg,
		m(
			".summary",
			css`
				& {
					display: grid;
					justify-content: center;
					justify-self: center;
					max-width: 40em;
					gap: 0.75em;
				}
			`,

			m(
				"." + legacyCSS.centeredHeader,
				"ORDER " + (c.get()[0] || {}).order_name
			),

			m("." + legacyCSS.centeredHeader, (c.get()[0] || {}).order_address),
			m("." + legacyCSS.leftText, "Thank you for your order!"),

			m(
				"." + legacyCSS.leftText,
				"Below is a summary of your order, please let us know if anything seems out of place. You can return to this page at anytime with the link we've just emailed you."
			),

			m("." + legacyCSS.leftTitles, "Price"),

			m(
				"." + legacyCSS.leftText,
				css`
					& {
						margin-left: 25px;
					}
				`,
				[money(R.sum(c.get().map((i) => i.price)), 0), " Incl. GST"].join(" ")
			),

			m("." + legacyCSS.leftTitles, "Inclusions"),

			m(
				".items",
				css`
					& {
						display: grid;
						gap: 0.5em;
					}

					& li {
						list-style: disc;
					}
				`,

				c.get().map((i) =>
					m(
						"ul.items" + +legacyCSS.leftText,
						css`
							& {
								display: grid;
								gap: 0.5em;
								--justify-content: space-around;
								--padding-right: 40px;
							}
						`,
						i.inclusions
							.filter(
								(i) =>
									i.indexOf("Hard Trenching") == -1 &&
									i.indexOf("Aerial Cabling") == -1
							)
							.concat(
								i.baseprice == i.price
									? []
									: c.get()[0].description["aerial"]
									? i.aerialinclusion
									: i.groundinclusion
							)
							.map((inc) => m("li." + legacyCSS.helper, inc))
					)
				)
			),
			m("." + legacyCSS.leftTitles, "Description"),

			m(
				"ul.items",
				css`
					& {
						display: grid;
						gap: 0.5em;
					}

					& li {
						list-style: disc;
					}
				`,

				qtags
					.filter((i) => i.c)
					.map((i) => {
						const a = i.a as
							| "charger"
							| "phase"
							| "evlocation"
							| "workingdistance"
							| "storey"
							| "propertyowner"
							| "separatedGarage"
							| "concretePavers"
							| "groundworkingdistance"
							| "aerial"

						return m(
							"li." + legacyCSS.helper,
							(store.prop(a).get() == "YES"
								? "Yes"
								: store.prop(a).get() == "NO"
								? "No"
								: store.prop(a).get()) +
								" " +
								i.q
						)
					})
			),

			m(
				"." + legacyCSS.spreadRowsCenter,
				m(
					HarthButton,
					{
						postop: printMe,
						sl: {
							size: "large",
							variant: "default",
							outline: true,
						},
					},
					[
						"Print",
						m(HarthIcon, {
							sl: {
								name: "printer",
								slot: "suffix",
								style: { "font-size": "18px" },
							},
						}),
					]
				)
			)
		)
	)
})
const Questions = bacta.component<{ step: string }>((v) => {
	const {
		store,
		l,
		c,

		workingdistance,
		separatedGarage,
		concretePavers,
		groundworkingdistance,
	} = useStore()

	const rendero = {
		workingdistance: false,
		storey: false,
		separatedGarage: false,
		concretePavers: false,
		groundworkingdistance: false,
		summary: false,
		dateframe: false,
		timeframe: false,
		terms: false,
		privacy: false,
		"submit-pay": false,
		paydeposit: false,
		emailedquote: false,
	}

	store.prop("verifiedstep").update(() => ({
		"/questions/payment": () =>
			(store.prop("checkoutindex").get()[0] &&
				!store.prop("checkoutindex").get()[0].description
					.inspectiondefaultime &&
				!store.prop("paydeposit").get()) ||
			(store.prop("checkoutindex").get()[0] &&
				!store.prop("checkoutindex").get()[0].description.defaultime &&
				store.prop("paydeposit").get()),
		"/questions/details": () =>
			!store.prop("order_contact_name").get() ||
			!store.prop("order_email").get() ||
			!store.prop("order_phone").get() ||
			!store.prop("ValidatedAddress").get() ||
			(store
				.prop("checkoutindex")
				.get()[0]
				.unavailable.find(
					(a) =>
						a == new Date(store.prop("dateframe").get() as Date).toISOString()
				)
				? true
				: false) ||
			l.get() ||
			(restrictedarea.has(
				Number(store.prop("FormalAddress").get().address.postalCode)
			)
				? false
				: true),

		"/questions/details-confirmation": () =>
			!store.prop("checkoutindex").get().length ||
			!(
				(store.prop("workingdistance").get() != 0 &&
					store.prop("separatedGarage").get() == "NO") ||
				(store.prop("separatedGarage").get() &&
					store.prop("workingdistance").get() != 0 &&
					store.prop("concretePavers").get() &&
					store.prop("groundworkingdistance").get() != 0)
			) ||
			(!(store.prop("checkoutindex").get()[0] || {}).order_welcome &&
				!store.prop("sendquote").get()) ||
			l.get() ||
			store.prop("propertyowner").get() == "NO",
		"/questions/photo-uploads": () => null,
		"/questions/welcome": () => null,
		"/questions/thankyou": () => null,
	}))

	v.useEffect({ name: "scroll-into-questions" }, function* () {
		// eslint-disable-next-line no-empty-pattern
		const [] = yield [
			store.prop("charger"),
			store.prop("phase"),
			store.prop("evlocation"),
			store.prop("workingdistance"),
			store.prop("storey"),
			store.prop("propertyowner"),
			store.prop("separatedGarage"),
			store.prop("concretePavers"),
			store.prop("groundworkingdistance"),
			store.prop("checkoutindex"),
			store.prop("FormalAddress"),
			store.prop("dateframe"),
			store.prop("privacy"),
			store.prop("terms"),
			store.prop("paydeposit"),
			store.prop("sendquote"),
			l,
		]

		setTimeout(
			() => {
				Object.keys(rendero).map((k) => {
					type renders =
						| "workingdistance"
						| "storey"
						| "separatedGarage"
						| "concretePavers"
						| "groundworkingdistance"
						| "summary"
						| "paydeposit"
						| "emailedquote"
					const element = document.getElementById(k || "")
					if (
						!rendero[k as renders] &&
						element &&
						(k != "submit-pay" ||
							(k == "submit-pay" && store.prop("paydeposit").get()))
					) {
						element?.scrollIntoView({
							behavior: "smooth",
							block: k == "submit-pay" ? "end" : "start",
						})
						rendero[k as renders] = true
					}
				})
			},
			store.prop("paydeposit").get() ? 1000 : 300
		)
	})

	return ({ attrs }) =>
		m(
			FormLayout,
			m(Wizard, {
				activeId: attrs.step,
				steps: [
					{
						id: "welcome",
						component: QuestionsWelcome,
						next: { disabled: false || l.get() },
					},
					{
						id: "details",
						component: QuestionConfirmation,
						next: {
							disabled: false || l.get(),
						},
					},
					{
						id: "details-confirmation",
						component: QuestionDetails,
						next: {
							disabled: store
								.prop("verifiedstep")
								.get()
								["/questions/details"](),
						},
					},
					{
						id: "payment",
						component: QuestionPayment,
						next: {
							disabled: store
								.prop("verifiedstep")
								.get()
								["/questions/details-confirmation"](),
						},
					},
					{
						id: "photo-uploads",
						component: PhotoDetails,
						next: {
							disabled:
								l.get() ||
								store.prop("verifiedstep").get()["/questions/payment"](),
						},
					},
					{
						id: "thankyou",
						component: QuestionsThankYou,
						next: {
							disabled: l.get(),
							action() {
								m.route.set("/welcome")
							},
						},
					},
				],
			})
		)
})

const Dealer = bacta.component<{ step: string }>((v) => {
	const { store, l } = useStore()
	return ({ attrs }) =>
		m(
			FormLayout,
			m(
				".splash-img",
				css`
					& {
						background: transparent url("assets/evgen-bg-1.png") center center /
							cover no-repeat;
						border-radius: var(--border-radius);
					}

					@media (min-aspect-ratio: 1/1) {
						& {
							min-height: 50dvh;
						}
					}
					@media (max-aspect-ratio: 1/1) {
						& {
							min-height: 30dvh;
						}
					}
				`
			),
			m(RegisterInterest)
		)
})

const privacy = bacta.component<{ step: string }>((v) => {
	const { store } = useStore()
	return ({ attrs }) => pp
})

const terms = bacta.component<{ step: string }>((v) => {
	const { store } = useStore()
	return ({ attrs }) => ts
})

const layout = (comp: bacta.ComponentTypes) =>
	(() =>
		({ attrs }) =>
			m(Layout, { comp, ...attrs })) as bacta.BactaComponent

m.route.prefix = ""

if (
	window.location.pathname.startsWith("/questions") &&
	!window.location.pathname.startsWith("/questions/welcome") &&
	window.history.state != "setorder"
) {
	window.history.replaceState(null, "", "/questions/welcome")
}

m.route((window as any).app, "/welcome", {
	"/welcome": layout(Welcome),
	"/referral/:ref": layout(Welcome),
	"/optout": layout(Goodbye),
	"/questions/:step": layout(Questions),
	"/dealer": layout(Dealer),
	"/privacy": layout(privacy),
	"/terms": layout(terms),
})
